<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="1000"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="ma-2">fas fa-money-check</v-icon> Forma de Pago
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Datos de Banco -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="selected_fp.solicita_banco == 1">
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  <v-icon class="ma-2">fas fa-university</v-icon> Datos del Banco
                </v-card-title>
                <v-card-text>
                  <v-form
                    v-model="formBanco"
                    ref="formBank"
                    lazy-validation
                  >
                    <!-- Banco, Cuenta y CBU -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs">
                      <v-col cols="12" sm="4" md="4" class="py-2">
                        <v-autocomplete
                          v-model="selectedBanco"
                          label="Banco"
                          item-text="banco_nombre"
                          :items="bancos"
                          return-object
                          outlined
                          dense
                          hide-details
                          clearable
                          @change="setCuentas()"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-2">
                        <v-autocomplete
                          v-model="selectedCuenta"
                          label="Cuenta"
                          item-text="numero"
                          :items="cuentas"
                          return-object
                          outlined
                          dense
                          hide-details
                          clearable
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-2">
                        <v-text-field
                          v-model="selectedCuenta.cbu"
                          label="CBU"
                          type="text"
                          hide-details
                          outlined
                          dense
                          readonly
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Fecha, Tipo de Movimiento y Nro. de Comprobante -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs">
                      <v-col cols="12" sm="4" md="4" class="py-2">
                        <FechaPicker
                          v-model="fechaCobro"
                          label="Fecha Cobro"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        />
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-2">
                        <v-autocomplete
                          v-model="selectedTipoMov"
                          label="Movimiento"
                          item-text="tipo_mov_nombre"
                          :items="tipos_movimientos"
                          return-object
                          outlined
                          dense
                          hide-details
                          clearable
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-2">
                        <v-text-field
                          v-model="nroComprobante"
                          label="N° Comprobante"
                          type="text"
                          hide-details
                          outlined
                          dense
                          clearable
                          :readonly="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Datos de Tarjeta y Cupón -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" v-show="selected_fp.tarjeta == 1">
            <!-- Datos de Tarjeta -->
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  <v-icon class="ma-2">fas fa-credit-card</v-icon> Datos de Tarjeta
                </v-card-title>
                <v-card-text>
                  <v-form 
                    v-model="formTar"
                    ref="formTarjeta"
                    lazy-validation
                  >
                    <!-- Tarjeta, ultimos 4 Tarj., cant. de cuotas e importe cuotas -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs">
                      <v-col cols="12" sm="5" md="5" class="py-1">
                        <v-autocomplete
                          v-model="selectedTarjeta"
                          label="Tarjeta"
                          item-text="tarjeta_nombre"
                          :items="tarjetas"
                          outlined
                          :filled="objetoNoVacio(selectedTarjeta) || !cadenaVacia(selected_fp.id)"
                          dense
                          :disabled="objetoNoVacio(selectedTarjeta) || !cadenaVacia(selected_fp.id)"
                          :rules="[rules.required]"
                          hide-details
                          return-object
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" class="py-1">
                        <v-text-field-integer
                          v-model="numTarjeta"
                          label="Últimos 4 Tarj."
                          v-bind:properties="{
                            disabled: !cadenaVacia(selected_fp.id),
                            outlined: true,
                            rules: [rules.required],
                            'validate-on-blur': true,
                            dense: true,
                            'hide-details': true,
                            clearable: true,
                            filled: !cadenaVacia(selected_fp.id)
                          }"
                          v-bind:options="{
                            inputMask: '####',
                            outputMask: '####',
                            empty: null
                          }"
                        />
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1" v-if="selected_fp.cuotas == 1">
                        <v-text-field
                          v-model="selected_fp.cuotas_desde"
                          label="Cuotas"
                          type="text"
                          hide-details
                          outlined
                          dense
                          readonly
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1" v-if="selected_fp.cuotas == 1">
                        <text-field-money
                          v-model="selected_fp.importe_cuotas"
                          label="Cuotas Importe"
                          v-bind:properties="{
                            prefix: '$',
                            'hide-details': true,
                            readonly: true,
                            filled: true
                          }"
                        />
                      </v-col>
                    </v-row>
                    <!-- Cliente y Terceros, datos de terceros -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs">
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-checkbox
                          v-model="terceros"
                          label="Terceros"
                          :true-value="1"
                          :false-value="0"
                          dense
                          hide-details
                          color="info"
                          :disabled="!cadenaVacia(selected_fp.id)"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-checkbox
                          v-model="cliente"
                          label="Cliente"
                          :true-value="1"
                          :false-value="0"
                          dense
                          hide-details
                          color="info"
                          :disabled="!cadenaVacia(selected_fp.id)"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="py-1" v-if="terceros == 1">
                        <v-text-field
                          v-model="titular"
                          label="Titular"
                          type="text"
                          hide-details
                          outlined
                          dense
                          clearable
                          :rules="terceros == 1 ? [rules.required] : null"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1" v-if="terceros == 1">
                        <v-text-field
                          v-model="titularDni"
                          label="Dni"
                          type="number"
                          hide-details
                          outlined
                          dense
                          clearable
                          :rules="terceros == 1 ? [rules.required] : null"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Datos de Cupón -->
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  <v-icon class="ma-2">fas fa-scroll</v-icon> Cupón de Pago
                </v-card-title>
                <v-card-text>
                  <v-form
                    v-model="formCupon"
                    ref="formCupones"
                    lazy-validation
                  >
                    <!-- Fecha de Cobro, Cód. de Autorización, Cupón y Lote -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs">
                      <v-col cols="12" sm="3" md="3" class="py-1">
                        <FechaPicker
                          v-model="fechaCobro"
                          label="Fecha Cobro"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        />
                      </v-col>
                      <v-col cols="12" sm="3" md="3" class="py-1" v-if="selected_fp.cod_autoriz == 1">
                        <v-text-field
                          v-model="codAutoriza"
                          label="Cód. Autorización"
                          type="text"
                          clearable
                          hide-details
                          outlined
                          dense
                          :rules="[rules.required]"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1" v-if="selected_fp.cupon_lote == 1">
                        <v-text-field
                          v-model="cupon"
                          label="Cupón"
                          type="text"
                          clearable
                          hide-details
                          outlined
                          dense
                          :rules="[rules.required]"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1" v-if="selected_fp.cupon_lote == 1">
                        <v-text-field
                          v-model="lote"
                          label="Lote"
                          type="text"
                          clearable
                          hide-details
                          outlined
                          dense
                          :rules="[rules.required]"
                          :disabled="!cadenaVacia(selected_fp.id)"
                          :filled="!cadenaVacia(selected_fp.id)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" class="ma-2" @click="activo = false">Volver</v-btn>
          <v-btn color="success" @click="setDatosFP()" v-if="cadenaVacia(selected_fp.id)"><v-icon small left>fas fa-plus</v-icon> Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextFieldMoney from '../util/TextFieldMoney.vue'
import FechaPicker from '../util/FechaPicker.vue'
import { cadenaVacia, objetoNoVacio } from '../../util/utils'
import moment from 'moment'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    TextFieldMoney,
    FechaPicker
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalVerFP', value)
      }
    },
    selected_fp: {
      get(){
        return this.datos.selected_fp
      }
    },
    tarjetas: {
      get(){
        return this.datos.tarjetas
      }
    },
    bancos: {
      get(){
        return this.datos.bancos
      }
    },
    cuenta_bancos: {
      get(){
        return this.datos.cuenta_bancos
      }
    },
    tipos_movimientos: {
      get(){
        return this.datos.tipos_movimientos
      }
    },
    empresa_codigo: {
      get(){
        return this.datos.empresa_codigo
      }
    }
  },
  data() {
    return {
      cadenaVacia: cadenaVacia,
      objetoNoVacio: objetoNoVacio,
      formTar: false,
      formCupon: false,
      formBanco: false,
      selectedTarjeta: {},
      numTarjeta: '',
      terceros: 0,
      cliente: 0,
      titular: '',
      titularDni: '',
      fechaCobro: moment(new Date()).format('DD/MM/YYYY'),
      codAutoriza: '',
      cupon: '',
      lote: '',
      rules: {
        required: value => !!value || 'Campo requerido'
      },
      fp: {},
      selectedBanco: {},
      selectedCuenta: {},
      cuentas: [],
      selectedTipoMov: {},
      nroComprobante: '',
      cbu: ''
    }
  },
  methods: {
    initForm(){
      this.fp = this.selected_fp
      if (this.selected_fp.tarjeta == 1){
        // datos de tarjeta
        if (!cadenaVacia(this.selected_fp.tarjeta_codigo) && parseInt(this.selected_fp.tarjeta_codigo) > 0) this.selectedTarjeta = this.tarjetas.filter(element => element.tarjeta_codigo == this.selected_fp.tarjeta_codigo)[0]
        // ultimos 4 de la tarjeta
        this.numTarjeta = this.selected_fp.datos.tarjeta_numero
        // terceros o cliente
        this.terceros = this.selected_fp.datos.terceros
        this.cliente = this.selected_fp.datos.cliente
        // titular
        this.titular = this.selected_fp.datos.titular
        this.titularDni = this.selected_fp.datos.titular_dni
        // fecha de cobro
        this.fechaCobro = cadenaVacia(this.selected_fp.datos.fecha_cobro) ? this.fechaCobro : this.selected_fp.datos.fecha_cobro
        // código de autorización
        this.codAutoriza = this.selected_fp.datos.codigo_autorizacion
        // cupón
        this.cupon = this.selected_fp.datos.cupon
        // lote
        this.lote = this.selected_fp.datos.lote
      }else{
        // datos de banco
        this.selectedBanco = cadenaVacia(this.selected_fp.datos.banco_codigo) ? {} : this.bancos.filter(element => element.banco_codigo == this.selected_fp.datos.banco_codigo)[0]
        // cuenta
        this.cuentas = objetoNoVacio(this.selectedBanco) ? this.cuenta_bancos.filter(element => element.banco_codigo == this.selectedBanco.banco_codigo && element.empresa_codigo == this.empresa_codigo) : []
        this.selectedCuenta = cadenaVacia(this.selected_fp.datos.banco_cuenta) ? {} : this.cuenta_bancos.filter(element => element.codigo == this.selected_fp.datos.banco_cuenta)[0]
        // fecha
        this.fechaCobro = cadenaVacia(this.selected_fp.datos.banco_fecha) ? this.fechaCobro : this.selected_fp.datos.banco_fecha
        // tipo de movimiento
        this.selectedTipoMov = cadenaVacia(this.selected_fp.datos.banco_tipo_movimiento) ? {} : this.tipos_movimientos.filter(element => element.tipo_mov_codigo == this.selected_fp.datos.banco_tipo_movimiento)[0]
        // nro de comprobante
        this.nroComprobante = this.selected_fp.datos.banco_comprobante
      }
    },
    limpiarForm(){
      this.formTar = false
      this.formCupon = false
      this.formBanco = false
      this.selectedTarjeta = {}
      this.numTarjeta = ''
      this.terceros = 0
      this.cliente = 0
      this.titular = ''
      this.titularDni = ''
      this.fechaCobro = moment(new Date()).format('DD/MM/YYYY')
      this.codAutoriza = ''
      this.cupon = ''
      this.lote = ''
      this.fp = {}
      this.selectedBanco = {}
      this.selectedCuenta = {}
      this.cuentas = []
      this.selectedTipoMov = {}
      this.nroComprobante = ''
      this.cbu = ''
    },
    validarFormTarjetas(){
      if (this.$refs.formTarjeta.validate()) return this.formTar = true
      else return this.formTar = false
    },
    validarFormCupon(){
      if (this.$refs.formCupones.validate()) return this.formCupon = true
      else return this.formCupon = false
    },
    valiadarFormBanco(){
      if (!objetoNoVacio(this.selectedBanco)) return {valido: false, msj: 'Debe seleccionar el Banco.'}
      if (!objetoNoVacio(this.selectedCuenta)) return {valido: false, msj: 'Debe seleccionar la Cuenta.'}
      if (cadenaVacia(this.fechaCobro)) return {valido: false, msj: 'Debe seleccionar la Fecha.'}
      if (!objetoNoVacio(this.selectedTipoMov)) return {valido: false, msj: 'Debe seleccionar el Tipo de Movimiento.'}
      if (cadenaVacia(this.nroComprobante)) return {valido: false, msj: 'Debe ingresar el Nro. de Comprobante.'}
      return {valido: true, msj: 'OK'}
    },
    setDatosFP(){
      // control de campos obligatorios
      if (this.selected_fp.tarjeta == 1){
        if (!this.validarFormTarjetas()) return
        if (!this.validarFormCupon()) return
      }else{
        let valido = this.valiadarFormBanco()
        if (valido.valido == false){
          this.$store.dispatch('show_snackbar', {
            text: valido.msj,
            color: 'info',
          })
          return
        }
      }
      if(this.selected_fp.tarjeta == 1 && this.numTarjeta.length < 4){
        return  this.$store.dispatch('show_snackbar', {
                  text: 'Ingresa los últimos 4 dígitos de la Tarjeta.',
                  color: 'info',
                })
      }
      // lleno los campos de acuerdo a si es tarjeta o banco
      if (this.selected_fp.tarjeta == 1){
        this.fp.datos.terceros = this.terceros
        this.fp.datos.cliente = this.cliente
        this.fp.datos.codigo_autorizacion = this.codAutoriza
        this.fp.datos.cupon = this.cupon
        this.fp.datos.fecha_cobro = this.fechaCobro
        this.fp.datos.lote = this.lote
        this.fp.datos.tarjeta_numero = this.numTarjeta
        this.fp.datos.titular = this.titular
        this.fp.datos.titular_dni = this.titularDni
      }else{
        this.fp.datos.banco_codigo = this.selectedBanco.banco_codigo
        this.fp.datos.banco_cuenta = this.selectedCuenta.codigo
        this.fp.datos.banco_fecha = this.fechaCobro
        this.fp.datos.banco_tipo_movimiento = this.selectedTipoMov.tipo_mov_codigo
        this.fp.datos.banco_comprobante = this.nroComprobante
        this.fp.datos.fecha_cobro = this.fechaCobro
      }
      // emito la respuesta
      this.$emit('addDataFP', this.fp)
      this.activo = false
    },
    setCuentas(){
      this.cuentas = []
      if (!objetoNoVacio(this.selectedBanco)) this.selectedBanco = {}
      // obtengo las cuentas
      this.cuentas = this.cuenta_bancos.filter(element => element.banco_codigo == this.selectedBanco.banco_codigo && element.empresa_codigo == this.empresa_codigo)
    },
  },
  watch: {
    activo: function(val){
      if (!val) {
        this.$refs.formCupones.resetValidation()
        this.$refs.formTarjeta.resetValidation();
        this.limpiarForm()
      }
      else {this.initForm()}
    },
    terceros: function(){
      if (this.terceros == 1){
        this.cliente = 0
      }
    },
    cliente: function(){
      if (this.cliente == 1){
        this.terceros = 0
        this.titular = ''
        this.titularDni = ''
      }
    }
  },
}
</script>

<style>

</style>