<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="1000"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="ma-2">fas fa-search</v-icon> Buscar Forma de Pago
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="detalles_fp"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Financiaciones para mostrar.
                  </v-alert>
                </template>
                <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="seleccionarFP(item)" v-on="on">
                        <v-icon small>far fa-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Seleccionar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { format_money } from '../../util/utils'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalSelectFP', value)
      }
    },
    detalles_fp: {
      get(){
        return this.datos.detalles_fp
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Descripcion', align: 'left', value: 'descripcion' },
        { text: 'Forma de Pago', align: 'left', value: 'fp_descripcion' },
        { text: 'Tarjeta Importe', align: 'left', value: 'tarjeta_importe', formatter: format_money },
        { text: 'Monto Cuotas', align: 'left', value: 'importe_cuotas', formatter: format_money },
        { text: 'Total Final', align: 'left', value: 'total_final', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      load: false,
      search: ''
    }
  },
  methods: {
    limpiarForm(){
      this.load = false
      this.search = ''
    },
    seleccionarFP(item){
      this.$emit('selectedFP', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
    }
  },
}
</script>

<style>

</style>